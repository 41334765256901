
import Vue from 'vue';

export default Vue.extend({
  name: 'Projekt',

  created () {
    this.$store.commit('setDarkMode', false)
  },
});
